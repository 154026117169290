<template>
  <search-filter
    ref="searchFilter"
    :headers="headers"
    :multiSelectColumns="multiSelectColumns"
    :columnsWithPredicates="columnsWithPredicates"
    :dropDownValues="dropDownValues"
    :dateColumns="dateColumns"
    :filters="filters"
    :results="results"
    :totalResults="totalResults"
    :totalDistinctResults="totalResults"
    :theme="colorTheme"
    :showCalendarChip="false"
    :showActiveAccountsChip="false"
    :quickSearchParam="quickSearchHierarchyTree"
    :refresh="refresh"
    :closeFiltersCard="closeHierarchyTreeFiltersCard"
    :setQuickSearchParam="setQuickSearchHierarchyTree"
    :resetQuickSearchParam="resetQuickSearchHierarchyTree"
    :loadPrepopulatedData="loadPrepopulatedData"
  ></search-filter>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import SearchFilter from "./SearchFilter.vue";

export default {
  props: ["results", "totalResults", "refresh"],
  components: {
    SearchFilter
  },
  data: () => ({
    headers: [
      {
        text: "Quick search",
        value: "quickSearch",
      },
      {
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Type",
        value: "type",
      },
    ],
    multiSelectColumns: ["type"],
    dateColumns: [],
    columnsWithPredicates: [],
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    ...mapState("filters", [
      "hierarchyTreeFilters",
      "quickSearchHierarchyTree",
      "closeHierarchyTreeFiltersCard"
    ]),
    ...mapState("prepopulatedData", ["entityTypes"]),
    filters: {
      get() {
        return this.hierarchyTreeFilters;
      },
      set(newValue) {
        this.setHierarchyTreeFilters(newValue);
      }
    },
  },
  methods: {
    ...mapMutations("filters", [
      "setHierarchyTreeFilters",
      "setQuickSearchHierarchyTree",
      "resetQuickSearchHierarchyTree"
    ]),
    ...mapActions("prepopulatedData", ["loadEntitySearchPrepopulatedData"]),
    dropDownValues(prop) {
      switch (prop) {
        case "type":
          return this.entityTypes;
        default:
          return [];
      }
    },
    async loadPrepopulatedData() {
      await this.loadEntitySearchPrepopulatedData();
    }
  },
};
</script>
