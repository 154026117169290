<template>
  <span style="display:flex;">
    <v-dialog v-model="datePickerDialog" width="300px">
      <template v-slot:activator="{ on, attrs }">
        <v-chip-group>
          <v-chip v-bind="attrs" v-on="on">
            <v-icon class="pr-1">
              mdi-calendar
            </v-icon>
            {{ chipDateCheck(period) }}
          </v-chip>
        </v-chip-group>
      </template>
      <v-date-picker
        type="month"
        @click:month="confirmDate"
        :color="theme.datePicker"
        v-model="period"
        scrollable
      >
      </v-date-picker>
    </v-dialog>
  </span>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { getMonthYearFromYearMonthPeriod } from "@/utils/format-util.js";

export default {
  props: ["theme"],
  data: () => ({
    datePickerDialog: false
  }),
  computed: {
    ...mapState("feeResultsAccounts", ["selectedDate"]),
    period: {
      get() {
        return this.selectedDate;
      },
      set(newValue) {
        this.setSelectedDate(newValue);
      },
    },
  },
  methods: {
    ...mapMutations("feeResultsAccounts", ["setSelectedDate"]),
    confirmDate() {
      this.datePickerDialog = false;
    },
    chipDateCheck(selectedPeriod) {
      let currentPeriod = new Date().toISOString().substr(0, 7);
      if (selectedPeriod && selectedPeriod < currentPeriod) {
        return (
          getMonthYearFromYearMonthPeriod(selectedPeriod) + " (In the Past)"
        );
      } else {
        return getMonthYearFromYearMonthPeriod(selectedPeriod);
      }
    }
  }
};
</script>
