<template>
  <v-chip-group>
    <v-chip dark :color="theme.button" @click="showFilter" class="btn">
      <v-icon>mdi-filter-variant</v-icon>
      Add Filter
    </v-chip>
  </v-chip-group>
</template>

<script>
export default {
  props: ["showFilter", "theme"],
  data: () => ({}),
  computed: {},
  methods: {},
};
</script>

<style scoped>
.btn {
  font-weight: 300;
}
</style>
