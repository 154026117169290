<template>
  <v-row
    :class="{
      'row-border': true,
      'default-width': !isPredicateInGroup,
      'in-group-width': isPredicateInGroup,
      'default-height': !expandHeight,
      'expand-height': expandHeight
    }"
  >
    <v-col class="mt-3" id="choose-filter" style="max-width: 16rem">
      <v-select
        :menu-props="{ offsetY: true }"
        :color="theme.dropDown"
        :item-color="theme.dropDownItem"
        v-model="columnValue"
        label="Choose filter type"
        outlined
        :items="headers"
        item-text="text"
        item-value="value"
        :item-disabled="disabledColumn"
        @change="handleColumnChange()"
      ></v-select>
    </v-col>
    <v-col
      class="auto"
      v-if="showPredicate"
      id="choose-predicate"
      style="max-width: 18%"
    >
      <v-select
        v-model="predicateValue"
        :items="getPredicateOptions(columnValue)"
        item-text="text"
        item-value="value"
        item-disabled="disabled"
        :color="theme.dropDown"
        :item-color="theme.dropDownItem"
        @change="handlePredicateChange()"
      >
        <template v-slot:selection="{ item }">
          <span class="d-flex justify-center" style="width: 100%">
            {{ item.text }}
          </span>
        </template>
      </v-select>
    </v-col>
    <v-col class="pt-2" id="filter-value" style="height: inherit">
      <template v-if="multiSelectColumns.includes(columnValue)">
        <v-select
          :color="theme.dropDown"
          :item-color="theme.dropDownItem"
          label="Set Filter Value"
          :items="dropDownValues(columnValue)"
          v-model="filterValue"
          :disabled="emptyColumnValue"
          :menu-props="{ offsetY: true }"
        >
        </v-select>
      </template>
      <template v-else-if="columnValue === 'billingFeeType'">
        <v-select
          :color="theme.dropDown"
          :item-color="theme.dropDownItem"
          label="Set Filter Value"
          :items="dropDownValues(columnValue)"
          item-text="displayName"
          item-value="value"
          v-model="filterValue"
          :disabled="emptyColumnValue"
          :menu-props="{ offsetY: true }"
        >
        </v-select>
      </template>
      <template v-else-if="dateColumns.includes(columnValue)">
        <v-menu
          v-model="showCalendar"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formattedDate"
              placeholder="Set Filter Value"
              append-icon="mdi-calendar"
              @click:append="showCalendar = true"
              :color="theme.textField"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterValue"
            :color="theme.datePicker"
            @input="showCalendar = false"
          ></v-date-picker>
        </v-menu>
      </template>
      <template v-else>
        <v-textarea
          class="pt-1"
          :height="expandHeightTextarea"
          v-if="predicateValue === 'in group'"
          solo
          autofocus
          no-resize
          v-model="filterValue"
          :color="theme.textField"
          label="Paste a comma or carriage return separated list to filter on."
        ></v-textarea>
        <v-text-field
          v-else
          v-model="filterValue"
          autofocus
          label="Search table"
          :color="theme.textField"
          :disabled="emptyColumnValue"
        ></v-text-field>
      </template>
    </v-col>
    <v-col style="max-width: 15rem;" class="pl-0">
      <v-row style="margin-top: 6px">
        <v-col cols="5" md="5">
          <v-btn
            outlined
            :disabled="disableCommitButtons"
            :color="theme.button"
            @click="close"
            class="btn-style"
          >
            Cancel
          </v-btn>
        </v-col>
        <v-col cols="5" md="5" v-if="!showUpdateButton">
          <v-btn
            class="btn-style"
            :dark="!disableCommitButtons"
            :disabled="disableCommitButtons"
            :color="theme.button"
            @click="addFilter"
          >
            Add Filter
          </v-btn>
        </v-col>
        <v-col cols="5" md="5" v-else>
          <v-btn
            class="btn-style"
            :dark="!disableCommitButtons && !valueNotChanged"
            :disabled="disableCommitButtons || valueNotChanged"
            :color="theme.button"
            @click="updateActiveFilter"
          >
            Update
          </v-btn>
        </v-col>
        <v-col cols="2" md="2">
          <v-icon @click="close" class="exit-icon" style="top: -30px">
            mdi-close
          </v-icon>
          <v-icon
            class="expand-btn_position"
            @click="expandFilterCard"
            style="font-size: 20px"
            v-if="isPredicateInGroup"
          >
            mdi-arrow-expand
          </v-icon>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { formatDate } from "@/utils/format-util.js";

export default {
  props: [
    "headers",
    "multiSelectColumns",
    "columnsWithPredicates",
    "dropDownValues",
    "isPredicates",
    "containsPredicates",
    "inGroupPredicates",
    "hasPredicates",
    "doesNotHavePredicates",
    "dateColumns",
    "theme",
    "filtersArray",
    "colValue",
    "predValue",
    "filtValue",
    "chipColumn",
    "chipPredicate",
    "chipValue",
    "activeChipIndex",
    "showFilterSearch",
    "refresh",
    "resetFilterInputs",
    "removeActiveFilter",
    "setQuickSearchParam",
    "loadPrepopulatedData"
  ],
  emits: [
    "update:filtersArray",
    "update:colValue",
    "update:chipPredicate",
    "update:filtValue",
    "update:chipColumn",
    "update:chipValue",
  ],
  data: () => ({
    quickSearchSelected: "",
    expandHeight: false,
    showCalendar: false,
  }),
  computed: {
    filters: {
      get() {
        return this.filtersArray;
      },
      set(newValue) {
        this.$emit("update:filtersArray", newValue);
      }
    },
    columnValue: {
      get() {
        return this.colValue;
      },
      set(newValue) {
        this.$emit("update:colValue", newValue);
      }
    },
    predicateValue: {
      get() {
        return this.predValue;
      },
      set(newValue) {
        this.$emit("update:predValue", newValue);
      }
    },
    filterValue: {
      get() {
        return this.filtValue;
      },
      set(newValue) {
        this.$emit("update:filtValue", newValue);
      }
    },
    formattedDate() {
      return formatDate(this.filterValue);
    },
    show: {
      get() {
        return this.showFilterSearch;
      },
      set(newValue) {
        this.$emit("update:showFilterSearch", newValue);
      }
    },
    currentChipColumn: {
      get() {
        return this.chipColumn;
      },
      set(newValue) {
        this.$emit("update:chipColumn", newValue);
      }
    },
    currentPredicateValue: {
      get() {
        return this.chipPredicate;
      },
      set(newValue) {
        this.$emit("update:chipPredicate", newValue);
      }
    },
    currentChipValue: {
      get() {
        return this.chipValue;
      },
      set(newValue) {
        this.$emit("update:chipValue", newValue);
      }
    },
    valueNotChanged() {
      if (this.showPredicate) {
        return (
          this.currentChipValue === this.filterValue &&
          this.currentPredicateValue === this.predicateValue
        );
      } else {
        return this.currentChipValue == this.filterValue;
      }
    },
    emptyColumnValue() {
      return (
        this.columnValue === null ||
        this.columnValue === undefined ||
        this.columnValue === ""
      );
    },
    emptyFilterValue() {
      return (
        this.filterValue === null ||
        this.filterValue === undefined ||
        this.filterValue === ""
      );
    },
    emptyPredicateValue() {
      return (
        this.predicateValue === null ||
        this.predicateValue === undefined ||
        this.predicateValue === ""
      );
    },
    disableCommitButtons() {
      if (this.emptyFilterValue) {
        return true;
      }
      if (this.showPredicate && this.emptyPredicateValue) {
        return true;
      }
      return false;
    },
    showUpdateButton() {
      if (
        this.currentChipColumn !== null &&
        this.currentChipColumn === this.columnValue
      ) {
        return true;
      }
      return false;
    },
    showPredicate() {
      return this.columnsWithPredicates.includes(this.columnValue);
    },
    isPredicateInGroup() {
      return this.predValue === "in group";
    },
    expandHeightTextarea() {
      if (this.expandHeight) {
        return "170";
      } else {
        return "60";
      }
    }
  },
  watch: {
    predicateValue(val) {
      if (val !== "in group") {
        this.expandHeight = false;
      }
    }
  },
  methods: {
    handleColumnChange() {
      setTimeout(() => {
        this.filterValue = null;
      }, 1);
      this.predicateValue = null;
      this.showCalendar = false;
      this.expandHeight = false;
    },
    handlePredicateChange() {
      this.expandHeight = false;
    },
    async close() {
      this.show = false;
      let columnValue = this.columnValue;
      this.resetFilterInputs();
      if (columnValue === "quickSearch") {
        await this.refresh();
      }
    },
    async addFilter() {
      if (this.columnValue === "quickSearch") {
        this.setQuickSearchParam(this.filterValue);
      } else {
        let predicate;
        if (this.multiSelectColumns.includes(this.columnValue)) {
          predicate = "is";
        } else if (this.predicateValue) {
          predicate = this.predicateValue;
        } else {
          predicate = "contains";
        }
        this.filters.push({
          column: this.columnValue,
          predicate: predicate,
          value: this.filterValue,
          text: this.getTextByValue(this.columnValue),
        });
      }
      this.expandHeight = false;
      this.resetFilterInputs();
      await this.refresh();
    },
    getTextByValue(columnValue) {
      if (columnValue === "billingFeeType") {
        return (
          this.predicateValue.charAt(0).toUpperCase() +
          this.predicateValue.slice(1)
        );
      }
      let text = "";
      for (var i = 0; i < this.headers.length; i++) {
        if (this.headers[i].value == columnValue) {
          text = this.headers[i].text;
          break;
        }
      }
      return text;
    },
    expandFilterCard() {
      this.expandHeight = !this.expandHeight;
    },
    async updateActiveFilter() {
      if (this.columnValue !== "quickSearch") {
        await this.removeActiveFilter(this.activeChipIndex, false, false);
      }
      await this.addFilter();
    },
    getPredicateOptions(columnValue) {
      let predicateOptions = [];
      let isOption = { text: "is", value: "is" };
      let containsOption = { text: "contains", value: "contains" };
      let inGroupOption = { text: "in group", value: "in group" };
      let hasOption = { text: "has", value: "has" };
      let doesNotHaveOption = { text: "does not have", value: "does not have" };
      if (this.isPredicates.includes(columnValue)) {
        predicateOptions.push(isOption);
      }
      if (this.containsPredicates.includes(columnValue)) {
        predicateOptions.push(containsOption);
      }
      if (this.inGroupPredicates.includes(columnValue)) {
        if (this.disableInGroupOption(columnValue)) {
          inGroupOption.disabled = true;
        }
        predicateOptions.push(inGroupOption);
      }
      if (this.hasPredicates.includes(columnValue)) {
        predicateOptions.push(hasOption);
      }
      if (this.doesNotHavePredicates.includes(columnValue)) {
        predicateOptions.push(doesNotHaveOption);
      }
      return predicateOptions;
    },
    disableInGroupOption(columnValue) {
      let i = 0;
      let found = false;
      while (i < this.filters.length && !found) {
        let filter = this.filters[i];
        if (filter.column === columnValue) {
          found = true;
        }
        i++;
      }
      if (found) {
        return true;
      } else {
        return false;
      }
    },
    disabledColumn(column) {
      let i = 0;
      let found = false;
      while (i < this.filters.length && !found) {
        let filter = this.filters[i];
        if (filter.column === column.value && filter.predicate === "in group") {
          found = true;
        }
        i++;
      }
      if (found) {
        return true;
      } else {
        return false;
      }
    },
  },
  async beforeMount() {
    await this.loadPrepopulatedData();
  },
};
</script>

<style>
#choose-predicate > div > div > .v-input__slot {
  margin-top: -4px;
}
#choose-filter > div > div {
  max-width: 98%;
}
#choose-filter .v-input__append-inner {
  margin-top: 12px !important;
}
#choose-filter .v-text-field--outlined .v-label {
  top: 14px !important;
}
#filter-value > div > div {
  max-width: 95%;
}
#filter-value .v-input__slot::before {
  bottom: 0px !important;
}
#filter-value .v-input .v-input__control .v-input__slot input {
  padding-top: 15px !important;
}
#filter-value .v-input .v-input__control .v-input__slot .v-select__slot .v-select__selections div {
  margin-bottom: -5px !important;
}
#filter-value > div > div > div > .v-text-field__slot > input {
  padding-bottom: 1rem;
  padding-left: 1rem;
}
#filter-value > div > div > div > .v-input__append-inner > div > button {
  padding-top: 1rem;
}
.row-border {
  position: relative;
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 20px -7px rgba(0, 0, 0, 0.42);
  box-shadow: 0px 0px 20px -7px rgba(0, 0, 0, 0.42);
}
.row-border .v-input__slot {
  min-height: 48px !important;
}
.btn-style {
  text-transform: none;
  font-weight: 500;
}
.btn-position {
  padding: 0 !important;
  display: flex !important;
  align-items: flex-end !important;
}
.expand-btn_position {
  position: absolute !important;
  bottom: 5px !important;
  right: 5px !important;
}
.transition-delay {
  transition-duration: 1s;
}
.in-group-width {
  max-width: 1250px;
}
.default-width {
  max-width: 900px;
}
.default-height {
  height: 90px;
}
.expand-height {
  height: 200px;
}
</style>
