var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showChipComponent)?_c('v-container',{staticClass:"ml-0 py-0"},[_c('v-row',[_c('v-chip-group',{attrs:{"column":""}},[(
          _vm.quickSearchValue !== null &&
            _vm.quickSearchValue !== undefined &&
            _vm.quickSearchValue.length > 0
        )?_c('v-chip',{attrs:{"close":""},on:{"click":_vm.setQuickSearchFilter,"click:close":_vm.removeQuickSearchChip}},[_vm._v(" \""+_vm._s(_vm.quickSearchValue)+"\" ")]):_vm._e()],1),_c('v-chip-group',{attrs:{"column":""}},_vm._l((_vm.filters),function(item,index){return _c('v-chip',{key:index,attrs:{"active":_vm.showChip(item),"close":""},on:{"click":function($event){return _vm.setFilter(index)},"click:close":function($event){return _vm.removeActiveFilter(index, true, true)}}},[(item.predicate === 'in group')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.text)+" "+_vm._s(_vm.inGroupText(item.value))+" ")])]}}],null,true)},[_c('span',[_c('p',{staticClass:"mb-0",domProps:{"innerHTML":_vm._s(_vm.hoverText(item))}})])]):_c('span',[_vm._v(" "+_vm._s(item.text)+": "+_vm._s(_vm.getDisplayName(item))+" ")])],1)}),1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }