function getInGroupFilterUniqueValues(filterValue) {
  if (!filterValue) {
    return [];
  }
  let values = filterValue.trim();
  let stringWithAllCommas = values.replaceAll("\n", ",");
  let valuesArray = stringWithAllCommas.split(",");
  let uniqueValues = [...new Set(valuesArray)];
  return uniqueValues;
}

module.exports = {
  getInGroupFilterUniqueValues
};
