<template>
  <v-container class="ml-0">
    <filters-card
      v-if="showFilterSearch"
      :headers="headers"
      :multiSelectColumns="multiSelectColumns"
      :columnsWithPredicates="columnsWithPredicates"
      :dropDownValues="dropDownValues"
      :isPredicates="isPredicates"
      :containsPredicates="containsPredicates"
      :inGroupPredicates="inGroupPredicates"
      :hasPredicates="hasPredicates"
      :doesNotHavePredicates="doesNotHavePredicates"
      :dateColumns="dateColumns"
      :theme="theme"
      :filtersArray.sync="filters"
      :colValue.sync="columnValue"
      :predValue.sync="predicateValue"
      :filtValue.sync="filterValue"
      :chipColumn.sync="currentChipColumn"
      :chipPredicate.sync="currentPredicateValue"
      :chipValue.sync="currentChipValue"
      :activeChipIndex="activeChipIndex"
      :showFilterSearch.sync="showFilterSearch"
      :refresh="refreshToFirstPage"
      :resetFilterInputs="resetFilterInputs"
      :removeActiveFilter="removeActiveFilter"
      :setQuickSearchParam="setQuickSearchParam"
      :loadPrepopulatedData="loadPrepopulatedData"
    ></filters-card>
    <v-row class="mt-3">
      <span>
        <inception-account-status-filter-chip
          v-if="showActiveAccountsChip"
          :theme="theme"
          class="mr-2"
        ></inception-account-status-filter-chip>
      </span>

      <span>
        <fee-review-calendar-filter-chip
          v-if="showCalendarChip"
          :theme="theme"
          class="mr-2"
        ></fee-review-calendar-filter-chip>
      </span>
      <span>
        <active-chips
          v-if="filtersActive"
          :filters="filters"
          :screen="screen"
          :billingFeeTypes="billingFeeTypes"
          :results="results"
          :totalResults="totalResults"
          :totalDistinctResults="totalDistinctResults"
          :quickSearchValue="quickSearchValue"
          :dateColumns="dateColumns"
          :setFilter="setFilter"
          :removeActiveFilter="removeActiveFilter"
          :setQuickSearchFilter="setQuickSearchFilter"
          :removeQuickSearchChip="removeQuickSearchChip"
        ></active-chips>
      </span>
      <span>
        <add-filter-button
          v-if="!showFilterSearch"
          :showFilter="showFilterComponent"
          :theme="theme"
        ></add-filter-button>
      </span>
    </v-row>
  </v-container>
</template>

<script>
import FiltersCard from "./FiltersCard.vue";
import ActiveChips from "./ActiveChips.vue";
import AddFilterButton from "./AddFilterButton.vue";
import FeeReviewCalendarFilterChip from "@/components/filters/fee-review/FeeReviewCalendarFilterChip.vue";
import InceptionAccountStatusFilterChip from "@/components/filters/inception/InceptionAccountStatusFilterChip.vue";

export default {
  props: [
    "headers",
    "multiSelectColumns",
    "columnsWithPredicates",
    "dropDownValues",
    "isPredicates",
    "containsPredicates",
    "inGroupPredicates",
    "hasPredicates",
    "doesNotHavePredicates",
    "billingFeeTypes",
    "dateColumns",
    "filters",
    "results",
    "totalResults",
    "totalDistinctResults",
    "theme",
    "showCalendarChip",
    "showActiveAccountsChip",
    "quickSearchParam",
    "refresh",
    "closeFiltersCard",
    "setQuickSearchParam",
    "resetQuickSearchParam",
    "loadPrepopulatedData",
    "screen"
  ],
  components: {
    FiltersCard,
    ActiveChips,
    AddFilterButton,
    FeeReviewCalendarFilterChip,
    InceptionAccountStatusFilterChip
  },
  data: () => ({
    showFilterSearch: false,
    columnValue: "quickSearch",
    predicateValue: null,
    filterValue: null,
    currentChipColumn: null,
    currentPredicateValue: null,
    currentChipValue: null,
    activeChipIndex: null,
    quickSearchTimer: null
  }),
  computed: {
    filtersActive() {
      if (
        (this.filters !== null && this.filters.length > 0) ||
        (this.quickSearchValue !== null && this.quickSearchValue.length > 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    quickSearchValue() {
      if (
        this.columnValue == "quickSearch" &&
        this.filterValue !== null &&
        this.filterValue !== undefined &&
        this.filterValue.length > 0
      ) {
        return this.filterValue;
      } else if (
        this.quickSearchParam !== null &&
        this.quickSearchParam !== undefined &&
        this.quickSearchParam.length > 0
      ) {
        return this.quickSearchParam;
      } else {
        return null;
      }
    },
    emptyFilterValue() {
      return (
        this.filterValue === null ||
        this.filterValue === undefined ||
        this.filterValue === ""
      );
    },
  },
  watch: {
    filterValue() {
      if (this.columnValue == "quickSearch") {
        clearTimeout(this.quickSearchTimer);
        this.quickSearchTimer = setTimeout(() => {
          this.quickSearch();
        }, 600);
      }
    },
    columnValue(newVal, oldVal) {
      if (oldVal == "quickSearch" && !this.emptyFilterValue) {
        this.refreshToFirstPage();
      }
    },
    closeFiltersCard(val) {
      if (!this.$cookies.isKey("token")) {
        return;
      }
      if (val == true) {
        this.reset();
      }
    },
  },
  methods: {
    showFilterComponent() {
      if (this.filtersActive) {
        this.resetFilterInputs();
      } else {
        this.createNewFilter("quickSearch");
      }
      this.showFilterSearch = true;
    },
    createNewFilter(columnValue) {
      this.columnValue = columnValue;
      this.predicateValue = null;
      this.filterValue = null;
    },
    setQuickSearchFilter() {
      this.filterValue = this.quickSearchValue;
      this.columnValue = "quickSearch";
      this.currentChipColumn = this.columnValue;
      this.currentPredicateValue = this.predicateValue;
      this.currentChipValue = this.filterValue;
      this.showFilterSearch = true;
    },
    async removeQuickSearchChip() {
      await this.resetQuickSearchParam();
      this.resetFilterInputs();
      if (this.columnValue == "quickSearch") {
        this.filterValue = null;
        await this.refreshToFirstPage();
      } else {
        await this.refreshToFirstPage();
      }
    },
    setFilter(prop) {
      this.columnValue = this.filters[prop].column;
      this.predicateValue = this.filters[prop].predicate;
      this.filterValue = this.filters[prop].value;
      this.currentChipColumn = this.columnValue;
      this.currentPredicateValue = this.predicateValue;
      this.currentChipValue = this.filterValue;
      this.activeChipIndex = prop;
      this.showFilterSearch = true;
    },
    async removeActiveFilter(activeIndex, reset, refresh) {
      this.filters.splice(activeIndex, 1);
      if (reset) {
        this.resetFilterInputs();
      }
      if (refresh) {
        await this.refreshToFirstPage();
      }
    },
    resetFilterInputs() {
      this.columnValue = null;
      this.predicateValue = null;
      this.filterValue = null;
      this.currentChipColumn = null;
      this.currentPredicateValue = null;
      this.currentChipValue = null;
      this.activeChipIndex = null;
    },
    async quickSearch() {
      await this.refreshToFirstPage();
    },
    async refreshToFirstPage() {
      await this.refresh({ page: 1 });
    },
    reset() {
      this.showFilterSearch = false;
      this.resetFilterInputs();
    },
  }
};
</script>
<style scoped>
@media (max-width: 1904px) {
  .container {
    max-width: 100% !important;
  }
}
@media (min-width: 1264px) {
  .container {
    max-width: 100% !important;
  }
}
@media (min-width: 960px) {
  .container {
    max-width: 100% !important;
  }
}
</style>
