<template>
  <td class="no-wrap">
    <span v-if="formattedDate">
      {{ formattedDate }}
    </span>
  </td>
</template>

<script>
import { formatDate } from "@/utils/format-util.js";
export default {
  props: ["value"],
  computed: {
    formattedDate() {
      return formatDate(this.value);
    },
  },
};
</script>
