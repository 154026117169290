<template>
  <v-container class="ml-0 py-0" v-if="showChipComponent">
    <v-row>
      <v-chip-group column>
        <v-chip
          v-if="
            quickSearchValue !== null &&
              quickSearchValue !== undefined &&
              quickSearchValue.length > 0
          "
          @click="setQuickSearchFilter"
          close
          @click:close="removeQuickSearchChip"
        >
          "{{ quickSearchValue }}"
        </v-chip>
      </v-chip-group>
      <v-chip-group column>
        <v-chip
          v-for="(item, index) in filters"
          :key="index"
          :active="showChip(item)"
          @click="setFilter(index)"
          close
          @click:close="removeActiveFilter(index, true, true)"
        >
          <v-tooltip top v-if="item.predicate === 'in group'">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ item.text }} {{ inGroupText(item.value) }}
              </span>
            </template>
            <span>
              <p v-html="hoverText(item)" class="mb-0"></p>
            </span>
          </v-tooltip>
          <span v-else> {{ item.text }}: {{ getDisplayName(item) }} </span>
        </v-chip>
      </v-chip-group>
    </v-row>
  </v-container>
</template>

<script>
import { formatDate } from "@/utils/format-util.js";
import { getInGroupFilterUniqueValues } from "@/utils/filters-util.js";

export default {
  props: [
    "filters",
    "results",
    "billingFeeTypes",
    "totalResults",
    "totalDistinctResults",
    "quickSearchValue",
    "dateColumns",
    "setFilter",
    "removeActiveFilter",
    "setQuickSearchFilter",
    "removeQuickSearchChip",
    "screen"
  ],
  data: () => ({}),
  computed: {
    showChipComponent() {
      return this.screen !== "InceptionFilters" || this.filters.length > 1;
    }
  },
  methods: {
    showChip(item) {
      return this.screen !== "InceptionFilters" || item.column !== "accountStatus"
    },
    inGroupText(inGroupValue) {
      let totalRequested = getInGroupFilterUniqueValues(inGroupValue).length;
      if (this.totalDistinctResults !== -1) {
        return `(${this.totalDistinctResults}/${totalRequested})`;
      }
      return `(${this.totalResults}/${totalRequested})`;
    },
    hoverText(item) {
      let totalRequested = getInGroupFilterUniqueValues(item.value).length;
      let text;
      if (this.totalDistinctResults !== -1) {
        text = `${this.totalDistinctResults} out of ${totalRequested} ${item.text}s found.`;
      } else {
        text = `${this.totalResults} out of ${totalRequested} ${item.text}s found.`;
      }
      return text;
    },
    getDisplayName(prop) {
      if (prop.column === "billingFeeType") {
        let chipValue = this.billingFeeTypes.find((e) => e.value == prop.value);
        return chipValue.displayName + " type";
      } else if (this.dateColumns.includes(prop.column)) {
        return formatDate(prop.value);
      } else {
        return prop.value;
      }
    }
  },
};
</script>
