<template>
  <v-dialog v-model="dialog" scrollable max-width="300px">
    <template v-slot:activator="{ on, attrs }">
      <v-chip-group>
        <v-chip color="default" v-bind="attrs" v-on="on">
          {{ filterName(status) }}
        </v-chip>
      </v-chip-group>
    </template>
    <v-card>
      <v-card-title>Select Account Status</v-card-title>
      <v-card-text style="height: 150px;">
        <v-radio-group v-model="status" column>
          <v-radio label="Active Accounts" value="active"></v-radio>
          <v-radio label="Closed Accounts" value="closed"></v-radio>
          <v-radio label="Active & Closed Accounts" value="activeClosedAccounts"></v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: () => ({
    dialog: false
  }),
  computed: {
    ...mapState("inceptionAccounts", ["accountStatusChipValue"]),
    status: {
      get() {
        return this.accountStatusChipValue;
      },
      set(newValue) {
        this.setAccountStatusChipValue(newValue);
      },
    },
  },
  watch: {
    status() {
      this.dialog = false;
    }
  },
  methods: {
    ...mapMutations("inceptionAccounts", ["setAccountStatusChipValue"]),
    filterName(column) {
      switch (column) {
        case "active":
          return "Active Accounts";
        case "closed":
          return "Closed Accounts";
        case "activeClosedAccounts":
          return "Active & Closed Accounts";
        default:
          return "Account Status";
      }
    },

  }
};
</script>
